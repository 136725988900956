<template>
  <div class="user-tasks">
    <div class="tasks__header">
      <div class="tasks__title">
        Таски пользователя
      </div>
      <ip-button theme="rounded" color="blue" @click="showCreateTaskModal($parent.user.phone)">
        Создать
      </ip-button>
      <div class="tasks__filters">
        <ip-select
          :options="statuses"
          label="Статус"
          placeholder="Не выбрано"
          @onSelect="selectStatus"
          :selected-item="status">
        </ip-select>
        <ip-select
          :options="types"
          label="Тема"
          placeholder="Не выбрано"
          @onSelect="selectType"
          :selected-item="type">
        </ip-select>
      </div>
    </div>
    <div class="tasks__table">
      <div class="table">
        <div class="table__head">
          <div class="table__col">
            Приоритет
          </div>
          <div class="table__col">
            Статус
          </div>
          <div class="table__col">
            Тема
          </div>
          <div class="table__col">
            Дата создания
          </div>
          <div class="table__col">
            Дата закрытия
          </div>
          <div class="table__col">
            Клиент
          </div>
          <div class="table__col">
            Роль
          </div>
          
        </div>
        <div class="table__body" v-if="isLoading">
          <div class="loader-container">
            <ip-loader />
          </div>
      </div>
        <div class="table__body" v-else>
          <task-row
            v-for="task in tasks"
            :key="task.id"
            :task="task" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import IpSelect from "../../components/IpSelect";
import IpButton from "../../components/IpButton";
import IpLoader from "../../components/IpLoader";
import apiRoutes from '../../consts/apiRoutes';
import TaskRow from "../../components/Tasks/TaskRow";

export default {
  name: 'user-reviews',

  components: {
    IpSelect,
    IpButton,
    IpLoader,
    TaskRow
  },

  data() {
    return {
      isLoading: true,
      statuses: [
        {
          value: 'open',
          text: 'Требуют выполнения'
        },
        {
          value: 'closed',
          text: 'Выполнены'
        },
        {
          value: 'all',
          text: 'Показать все'
        }
      ],
      types: [
        {
          value: 'all',
          text: 'Все темы'
        },
        {
          value: 'user_documents_verification',
          text: 'Проверка личных документов'
        },
        {
          value: 'object_documents_verification',
          text: 'Проверка документов на недвижимость'
        },
        {
          value: 'chat_abuse',
          text: 'Жалоба из чата'
        },
        {
          value: 'review_dispute',
          text: 'Спорный отзыв'
        },
        {
          value: 'contract_dispute',
          text: 'Спор при прекращении договора'
        },
        {
          value: 'incoming_request',
          text: 'Входящий запрос'
        },
        {
          value: 'consultation',
          text: 'Консультация'
        },
        {
          value: 'law_and_security',
          text: 'Юридические или вопросы безопасности'
        },
        {
          value: 'else',
          text: 'Другое'
        }
      ],
      status: 'open',
      type: 'all',
      tasks: [],
      limit: 500,
      offset: 0,
    };
  },

  mounted() {
    this.fetchTasks();
  },

  methods: {
    ...mapMutations('task', [
      'showCreateTaskModal'
    ]),

    async fetchTasks() {
      this.isLoading = true;
      const data = {
        limit: this.limit,
        offset: 0,
        type: this.type,
        status: this.status,
        clientId: +this.$route.params.id
      };

      if (this.type === 'all') {
        delete data.type;
      }

      if (this.status === 'all') {
        delete data.status;
      }

      try {
        const result = await this.axios.post(apiRoutes.clientTasks, data);
        this.tasks = result.data.tasks;
        this.total = result.data.total;
        this.isLoading = false;
      } catch(error) {
        console.warn(error);
        this.isLoading = false;
      }
    },

    selectStatus(status) {
      this.status = status;
      this.fetchTasks();
    },

    selectType(type) {
      this.type = type;
      this.fetchTasks();
    }

  }
}
</script>

<style scoped lang="scss">
.user__body{
  flex: 1 1 auto;
  height: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.user-tasks{
  height: 100%;
  .tasks{
    &__header{
      display: flex;
      align-items: flex-end;
      padding: 19px 24px;
      flex: none;
    }
    &__title{
      margin-right: 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      color: $black;
    }
    &__filters{
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ::v-deep .ip-select{
        width: 200px;
        margin-left: 10px;
      }
    }
    &__table{
      flex: 1 1 auto;
      overflow: hidden;
      .table{
        height: 100%;
        .table__row{
          text-decoration: none;
        }
        .table__col{
          &:nth-child(1) {
            width: 10%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 27%;
          }
          &:nth-child(4) {
            width: 13%;
          }
          &:nth-child(5) {
            width: 13%;
          }
          &:nth-child(6) {
            width: 14.6%;
          }
          &:nth-child(7) {
            width: 11.6%;
          }
        }
      }
      .type-block{
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>